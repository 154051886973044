<template>
  <v-form :lazy-validation="true" @submit.prevent="handleSubmit" v-model="valid">
    <v-card>
      <v-toolbar color="primary" dark flat>
        <v-toolbar-title>{{ $t('common.create') }}</v-toolbar-title>
        <div class="flex-grow-1"></div>
      </v-toolbar>
      <v-card-text>
        <v-text-field
          label="Namn"
          name="name"
          prepend-icon="domain"
          type="text"
          v-model="name"
          :rules="nameRules"
        ></v-text-field>
      </v-card-text>

      <v-card-text>
        <v-text-field
          label="Org-nummer"
          name="orgNumber"
          prepend-icon="domain"
          type="text"
          v-model="orgNumber"
          :rules="orgNumberRules"
        ></v-text-field>
      </v-card-text>

      <v-card-actions>
        <v-btn color="primary" type="submit" x-large>{{ $t("common.create") }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  name: "CompanyCreate",
  data() {
    return {
      submitted: false,
      valid: false,
      name: "",
      orgNumber: "",
      nameRules: [
        v => !!v || this.$t("company.validation.nameRequired"),
        v => (v && v.length <= 255) || this.$t("company.validation.nameMax")
      ],
      orgNumberRules: [
        v => !!v || this.$t("company.validation.orgNumberRequired"),
        v =>
          /([5]\d{5}[-]\d{4})|([5]\d{9}\s)/.test(v) ||
          this.$t("company.validation.orgNumberFormatError")
      ]
    };
  },
  computed: {
    ...mapState("users", ["status", "currentUser", "userCompany", "hasCompany"])
  },
  methods: {
    ...mapActions("companies", ["createCompany"]),
    handleSubmit() {
      this.submitted = true;

      const { name, orgNumber } = this;

      if (name && orgNumber)
        this.createCompany({
          company: { name, orgNumber }
        });
    }
  },
  created() {
    if (this.hasCompany) this.$router.push("/company");
  }
};
</script>

<style>
</style>